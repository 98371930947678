html {
    height: 100%;
    scroll-behavior: smooth;
  }
  
.accesscode_for_pods {
    /* position: fixed; */
    height: 100%;
    overflow: auto;
    /* overflow: hidden; */
  }